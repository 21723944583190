import { fromUnixTime, format } from 'date-fns';
import { Box, Typography, TextField, Chip } from '@mui/material';
import { StatusChip } from 'src/components/base/StatusChip';

const statuses = {
  active: { title: 'Active', color: '#68ac23' },
  in_trial: { title: 'Trial', color: '#dea928' },
  cancelled: { title: 'Cancelled', color: '#cc3434' },
  non_renewing: { title: 'Non Renewing', color: '#575f77' },
}

export const SubscriptionSubheader = ({ subscription: { data: subscription, estimates } }) => {
  const { status, activation_code } = subscription;
  const valid_through = estimates?.subscription_estimate?.next_billing_at ?? subscription.valid_through;
  if (!status || !valid_through) { return null }

  const st = statuses[status] ?? statuses[0];
  const valid_though = format(fromUnixTime(valid_through), 'PPP');
  return (
    <>
      <StatusChip color={st.color} label={st.title} />
      {status !== 'cancelled' && <Typography color="textSecondary" variant="caption">&nbsp;Valid through {valid_though}. Activation code: {activation_code}</Typography>}
    </>
  )
}

export const SubscriptionTitle = ({ title = '', defaultTitle = '', onChange }) => (
  <Box sx={{ mb: 1 }}>
    <TextField value={title} placeholder={defaultTitle} variant="standard" onChange={event => onChange(event.target.value)} fullWidth />
  </Box>
)

export const SubscriptionStaticTitle = ({ title = '', count, onClick }) => {
  const subtitle = !!count && (count > 1 ? `${count} licenses` : `${count} license`);
  return (
    <>
      <Typography sx={[onClick && { cursor: 'pointer' }]}
        display="inline" variant='h5' onClick={onClick}>
        {title}&nbsp;
      </Typography>
      {subtitle && <Chip size="small" label={subtitle} />}
    </>
  )
}
