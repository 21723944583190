import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

const ChipStyled = styled(Chip, { shouldForwardProp: prop => prop !== 'color' })(({ theme, color }) => {
  const { light, contrastText } = theme.palette.augmentColor({ color: { main: color } });
  return {
    backgroundColor: light,
    color: contrastText,
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    },
  }
})

export const StatusChip = ({ color, label }) => (
  <ChipStyled label={label} color={color} variant="outlined" size="small" />
)
