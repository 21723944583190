import { useMemo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Fab, Unstable_Grid2 as Grid, Button, IconButton, Tooltip } from '@mui/material';
import { Card, CardHeader, CardContent, Skeleton, Alert, AlertTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Add as AddIcon, Settings as SettingsIcon } from '@mui/icons-material';

import { SubscriptionFooter } from './SubscriptionFooter';
import { SubscriptionDialog } from './SubscriptionDialog';
import { PaymentConfirmation } from './PaymentConfirmation';
import { SubscriptionStaticTitle, SubscriptionSubheader } from './SubscriptionHeaders';
import { MultilineTypography } from 'src/components/base/MultilineTypography';
import { AlertDialog } from 'src/components/base/AlertDialog';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { BottomBar } from 'src/components/base/BottomBar';
import { showChargebeePortal, PAYMENT_SOURCES } from 'src/services/chargebee';
import { SUBSCRIPTION_STATUS_CANCELLED } from 'shared/services/chargebee/constants';

import { runAction, runActivity } from 'src/services/utils';
import { useStore } from 'src/stores';

export const Subscriptions = observer(({ subsciption_id }) => {
  const { userStore } = useStore();

  const messageDialog = useModalDialog();
  const subscripionDialog = useModalDialog();
  const paymentConfirmationDialog = useModalDialog();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('f');

  const isProceedAvailable = useMemo(() => userStore.subscriptions.some(i => !!i.estimates), [userStore.subscriptions]);
  const filteredSubscriptions = useMemo(() => {
    if (!filter) { return userStore.subscriptions }

    return userStore.subscriptions.filter(({ data: { id, name, licenses = [] } }) => {
      return (id && id.toLowerCase().includes(filter)) ||
        (name && name.toLowerCase().includes(filter)) ||
        licenses.some(({ name, license_email }) => (name || '').toLowerCase().includes(filter) || (license_email || '').toLowerCase().includes(filter));
    });
  }, [userStore.subscriptions, filter]);

  useEffect(() => {
    runAction(async () => !userStore.subscriptions.length && (await userStore.fetchSubscriptions()))
      .then(() => {
        const subscripion = userStore.subscriptions?.find(({ key }) => key === subsciption_id);
        if (!!subsciption_id && !subscripion) { return userStore.logout() }
        if (subscripion) { return handleManageSubscription(subscripion) }
      })
  }, []);

  async function handleManageSubscription(subscription) {
    navigate(`/subscriptions/${subscription.key}`);
    const result = await subscripionDialog.open({ subscription });
    navigate(`/subscriptions`);
    if (result) {
      userStore.modifySubscription(result);
    }
  }

  function handleSubscriptionChange(subscription) {
    userStore.modifySubscription(subscription);
  }

  function handleResetClick() {
    userStore.resetSubscriptionChanges();
  }

  async function handleAddSubscription() {
    const { email, first_name, last_name } = userStore.customer;
    const name = [first_name, last_name].join(' ');

    const subscription = userStore.createSubscription({ plan_id: '', licenses: [{ name, license_email: email }] });
    const result = await subscripionDialog.open({ subscription });
    if (result) {
      userStore.modifySubscription(result);
    }
  }

  async function handleProceedPayment() {
    const { total, credits } = userStore.subscriptions.reduce((acc, { estimates }) => {
      acc.total += estimates?.unbilled_charge_estimates?.amount ?? 0;
      acc.credits += estimates?.credit_note_estimates?.total ?? 0;
      return acc;
    }, { total: 0, credits: userStore.customer.promotional_credits + userStore.customer.refundable_credits });

    if (userStore.customer.auto_collection) {
      // Show warning only if user has not paid invoices or unbilled chareges
      if (!!userStore.customer.notpaid_invoices || !!userStore.customer.unbilled_charges) {
        if (!(await messageDialog.open({
          title: 'Unpaid Invoices', cancelable: true,
          children: `You have unpaid invoices on your account. You can't make any changes that modify the balance of your account until all your invoices are paid. Proceeding will automatically collect all unpaid invoices. Would you like to continue?`
        }))) { return }
      }

      // Ask payment method only if user has auto_collection and has to pay for anything
      if (userStore.customer.payment_method?.status === 'required' && (!!userStore.customer.notpaid_invoices || !!userStore.customer.unbilled_charges || total > credits)) {
        await new Promise((resolve) => showChargebeePortal(PAYMENT_SOURCES, resolve))
        await runAction(async () => await userStore.getCustomer());

        if (userStore.customer.payment_method.status === 'required') {
          return await messageDialog.open({
            title: 'Payment Methods',
            children: <>You need to provide a valid payment method in order to make changes to your subscripion(s).<hr />Reach out to <a href="mailto:sales@xappex.com">sales@xappex.com</a> if you would  like to use invoicing for payments.</>
          })
        }
      }
    }

    // Show invoice overview
    const estimates = userStore.subscriptions.filter(i => !!i.estimates);
    if (!(await paymentConfirmationDialog.open({ customer: userStore.customer, subscriptions: estimates, plans: userStore.plans }))) { return }

    try {
      await runActivity(() => userStore.proceedPayment(), true);
      userStore.displaySuccessSnack('All changes have been successfully processed.');
    }
    catch ({ message }) {
      await messageDialog.open({
        title: 'Charge Failed',
        children: <>Unfortunately, there was a failure when we tried to bill the credit card on your account:<hr />{message}<hr />You will need to either use a different card or clear us with your bank so that charges for our products are allowed. All the changes you have made to your subscription(s) are still in place, however, we will roll them back automatically if we still can’t bill the credit card associated with your account within 1 (one) week. Thank you for understanding.</>
      });
      await runAction(() => Promise.all([userStore.getCustomer(), userStore.fetchSubscriptions()]));
    }
  }

  return (
    <Grid container alignItems="stretch" direction="column" justifyContent="center" xs={11} md={7} wrap="nowrap" sx={{ pb: '56px' }}>
      {isProceedAvailable &&
        <Alert severity="warning" sx={{ my: 1 }}>
          <AlertTitle>Some changes have been made to your subscription(s). You will need to click on Proceed button at the bottom of the screen for the changes to take effect</AlertTitle>
        </Alert>
      }

      {filteredSubscriptions.map((subscription) => {
        const { key, data } = subscription;
        const plan = userStore.plans.find(plan => plan.id === data.plan_id) || {};
        const disabled = !userStore.customer.auto_collection && data.status === SUBSCRIPTION_STATUS_CANCELLED;
        const handler = disabled ? undefined : () => handleManageSubscription(subscription);

        return (
          <Card key={key} sx={{ mb: 1 }}>
            <CardHeader
              title={<SubscriptionStaticTitle title={data.name || plan.name} count={data.plan_quantity} onClick={handler} />}
              subheader={<SubscriptionSubheader subscription={subscription} />}
              action={!disabled && <IconButton onClick={handler} size="large"><SettingsIcon /></IconButton>}
            />

            <CardContent>
              {!!plan.description
                ? <MultilineTypography variant="caption" description={plan.description} />
                : <Skeleton width="100%" />
              }
            </CardContent>

            <SubscriptionFooter subscription={subscription} onChange={handleSubscriptionChange} />

          </Card>
        );
      })}

      <BottomBar position="fixed" color="default">
        <Grid container xs={11} md={7} direction="row" alignItems="center" justifyContent="space-between">
          <Grid>
            <Button
              disabled={!isProceedAvailable || userStore.isLoading}
              onClick={handleResetClick}>Reset</Button>

            <LoadingButton variant="contained" color="primary" sx={{ ml: 1 }}
              disabled={!isProceedAvailable || userStore.isLoading} loading={userStore.isLoading}
              onClick={handleProceedPayment}>Proceed</LoadingButton>
          </Grid>
          <Tooltip title="Add new subscription" placement="top" enterDelay={500} aria-label="Add">
            <span>
              <Fab onClick={handleAddSubscription} disabled={userStore.isLoading} sx={{ top: -20 }} color="secondary" aria-label="Add">
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>
        </Grid>
      </BottomBar>

      <AlertDialog {...messageDialog.register()} />

      <SubscriptionDialog {...subscripionDialog.register()} />

      <PaymentConfirmation {...paymentConfirmationDialog.register()} />

    </Grid >
  );
})
