import accounting from 'accounting';
import { Typography, TableCell } from '@mui/material';

export const AmountCell = ({ currency, amount = 0, negative = false }) => {
  let total = accounting.formatMoney(amount, { symbol: currency, format: '%s %v' });
  return (
    <TableCell align="right">
      <Typography variant="button" noWrap>{negative ? `(${total})` : total}</Typography>
    </TableCell>
  )
}