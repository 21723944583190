export const CODE_OK = 'ok';
export const CODE_UNKNOWN = 'unknown';
export const CODE_MSGRAPH_UNAUTHORIZED = 'msgraph_unauthorized';
export const CODE_SALESFORCE_UNAUTHORIZED = 'sf_unauthorized';

export const CODE_USER_INVALID = 'user_invalid';
export const CODE_UNEXPECTED_ERROR = 'unexpected_error';
export const CODE_TOKEN_EXPIRED = 'token_expired';
export const CODE_ACTIVATION_TOKEN_EXPIRED = 'activation_token_expired';

export const CODE_SF_REPORT_FAILED = 'sf_report_failed';

export const CODE_USER_NOTFOUND = 'user_notfound';

export const CODE_PASS_INVALID = { code: 'invalid_password', message: 'Your password must be at least 8 characters long and contain at least one digit and one special symbol.' }
export const CODE_PASS_COMMON = { code: 'common_password', message: 'You chose a commonly used password. Please use a different one.' }

export const CODE_INVALID_CREDENTIALS = { code: 'invalid_credentials', message: 'Invalid username or password' }

export const CODE_NEED_ACTIVATION = 'need_activation';
export const CODE_EXISTING_USER = 'existing_user';

export const CODE_INVALID_ACTIVATION_CODE = 'invalid_activation_code';
export const CODE_INSUFFICIENT_LICENSES = 'insufficient_licenses';
export const CODE_INVALID_LICENSE = 'invalid_license';

export const CODE_CHARGE_FAILED = 'charge_failed';

export const CODE_TFA_REQUIRED = 'tfa_required';
export const CODE_TFA_INVALID = { code: 'tfa_invalid', message: 'The verification code you entered is incorrect or expired. Please check your authenticator app and try again.' }