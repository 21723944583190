import { useRef, useState, useEffect } from 'react';
import { fromUnixTime, format } from 'date-fns';

import { observer } from 'mobx-react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { Card, CardHeader, CardContent } from '@mui/material';
import { IconButton, Button, Tooltip, Typography } from '@mui/material';
import { TableContainer, Table, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import { Download as DownloadIcon, Payment as PaymentIcon } from '@mui/icons-material';
import { SubmitAction } from 'shared/components/SubmitAction';
import { getDownloadInvoiceUrl } from 'src/api';
import { AlertDialog } from 'src/components/base/AlertDialog';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { runAction, runActivity, formatCurrency } from 'src/services/utils';
import { showChargebeePortal, PAYMENT_SOURCES } from 'src/services/chargebee';
import { InvoiceDialog, InvoiceStatusChip } from './InvoiceDialog';
import { useStore } from 'src/stores';
import { INVOICE_STATUS_PAYMENT_DUE, INVOICE_STATUS_NOT_PAID } from 'shared/services/chargebee/constants';

const PAGE_LIMIT = 10;
const ROW_HEIGHT = 82;

export const Invoices = observer(() => {
  const submitFormRef = useRef();
  const { userStore } = useStore();

  const [page, setPage] = useState(0);
  const [pending, setPending] = useState(false);

  const messageDialog = useModalDialog();
  const invoiceDialog = useModalDialog();

  const { token, customer } = userStore;
  const { payment_method, auto_collection } = customer;

  const invoices = userStore.invoices[page] ?? {};
  const { invoices: items = [], next_offset } = invoices;

  useEffect(() => { runAction(async () => await userStore.fetchInvoices(page, PAGE_LIMIT)) }, [page]);

  const handleInvoicePayment = async (invoice) => {
    // Ask payment method only if user has auto_collection and has to pay for anything
    if (payment_method?.status === 'required') {
      await new Promise((resolve) => showChargebeePortal(PAYMENT_SOURCES, resolve))
      await runAction(async () => await userStore.getCustomer());

      if (payment_method.status === 'required') {
        return await messageDialog.open({
          title: 'Payment Methods',
          children: <>You need to provide a valid payment method in order to pay your invoice.<hr />Reach out to <a href="mailto:sales@xappex.com">sales@xappex.com</a> if you would  like to use invoicing for payments.</>
        })
      }
    }

    if (!await invoiceDialog.open({ invoice })) { return }

    try {
      await runActivity(async () => await userStore.collectInvoice(invoice.id), true);
      userStore.displaySuccessSnack('The invoice has been successfully paid.');
    }
    catch ({ message }) {
      await messageDialog.open({
        title: 'Payment Failed',
        children: <>Unfortunately, there was a failure when we tried to bill the credit card on your account:<hr />{message}<hr />You will need to either use a different card or clear us with your bank so that charges for our products are allowed. All the changes you have made to your subscription(s) are still in place, however, we will roll them back automatically if we still can’t bill the credit card associated with your account within 1 (one) week. Thank you for understanding.</>
      });
    }
  }

  const handleDownloadClick = (invoice) => {
    const input = submitFormRef.current.querySelector(`input[name="id"]`);
    input.value = invoice.id;

    setPending(true);
    submitFormRef.current.submit();
    setTimeout(() => setPending(false), 2000);
  }

  const emptyRows = Math.max(0, PAGE_LIMIT - items.length);

  return (
    <Grid container alignItems="stretch" direction="column" justifyContent="center" xs={11} md={7} wrap="nowrap" sx={{ pb: '56px' }}>
      <Card>
        <CardHeader title="Billing History" />

        <CardContent>

          <TableContainer>
            <Table size='medium'>
              <TableBody>
                {items.map((invoice, index) => <InvoiceRow key={index} invoice={invoice} pending={pending} onPay={auto_collection && handleInvoicePayment} onDownload={handleDownloadClick} />)}

                {emptyRows > 0 && (
                  <TableRow style={{ height: ROW_HEIGHT * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination count={-1} page={page}
            labelDisplayedRows={({ from, to }) => `${from}–${to}`}
            rowsPerPage={PAGE_LIMIT} rowsPerPageOptions={[]} onPageChange={(_, page) => setPage(page)}
            slotProps={{ actions: { nextButton: { disabled: !next_offset } } }}
            component="div"
          />

        </CardContent>
      </Card>

      <SubmitAction ref={submitFormRef} action={getDownloadInvoiceUrl()} token={token} type="Basic" params={{ id: '' }} />

      <AlertDialog {...messageDialog.register()} />
      <InvoiceDialog {...invoiceDialog.register()} />

    </Grid >
  );
})

const InvoiceRow = ({ invoice, onPay, onDownload, pending }) => {
  const { status, description, total, amount_to_collect, currency_code, date } = invoice;
  const dateValue = format(fromUnixTime(date), 'PPP');
  const totalValue = formatCurrency(total / 100, currency_code);

  return (
    <TableRow hover role="checkbox" style={{ maxHeight: ROW_HEIGHT, height: ROW_HEIGHT }}>
      <TableCell variant="head">
        {description}<br />
        <InvoiceStatusChip status={status} />
        <Typography color="textSecondary" variant="caption">&nbsp;{dateValue}</Typography>
      </TableCell>

      <TableCell align="right" padding="none">
        {!!onPay && !!amount_to_collect && [INVOICE_STATUS_PAYMENT_DUE, INVOICE_STATUS_NOT_PAID].includes(status) && (
          <Button size="small" variant="outlined" disabled={pending} onClick={() => onPay(invoice)}>Pay</Button>
        )}
        <Tooltip enterDelay={500} placement="top" title="Download Invoice">
          <IconButton size="large" disabled={pending} onClick={() => onDownload(invoice)}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </TableCell>

      <TableCell align="right" size="small" sx={{ width: 30 }}>
        {totalValue}
      </TableCell>

    </TableRow >
  )
}