import accounting from 'accounting';
import { fromUnixTime, format } from 'date-fns';

import { DialogTitle, DialogActions, DialogContent, Typography, Button } from '@mui/material';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { AmountCell } from 'src/components/base/TableCells';
import { withModalDialog } from 'src/components/base/ModalDialog';

export const PaymentConfirmation = withModalDialog(({ customer, subscriptions, plans, onClose }) => {
  const { preferred_currency_code, promotional_credits = 0, refundable_credits = 0, unbilled_charges = 0, auto_collection } = customer;
  const total = subscriptions.reduce((total, { estimates: { credit_note_estimates, unbilled_charge_estimates } }) => {
    total += unbilled_charge_estimates?.amount ?? 0;
    total -= unbilled_charge_estimates?.discount_amount ?? 0;

    total -= unbilled_charge_estimates?.voided_amount ?? 0;
    total -= credit_note_estimates?.total ?? 0;
    return total;
  }, 0);

  const credits = promotional_credits + refundable_credits;
  const total_amount = total + unbilled_charges;

  const applied_credits = Math.max(0, total_amount < credits ? total_amount : credits);
  const final_credits = Math.max(0, credits - total_amount);

  const amount_due = Math.max(0, total_amount - credits);

  return (
    <>
      <DialogTitle id="form-dialog-title">Invoice Preview</DialogTitle>

      <DialogContent>
        <Table size="small">
          <TableBody>

            {subscriptions.map(item => <EstimateRow key={item.key} plans={plans} currency={preferred_currency_code} subscription={item} />)}

            <TableRow>
              <TableCell size="medium"><Typography sx={{ fontWeight: 'bold' }} variant="button" color="textPrimary">Total</Typography></TableCell>
              <AmountCell currency={preferred_currency_code} amount={Math.abs(total / 100)} negative={total < 0} />
            </TableRow>

            {!!applied_credits &&
              <TableRow>
                <TableCell><Typography variant="button" color="textPrimary">Credits Applied</Typography></TableCell>
                <AmountCell currency={preferred_currency_code} amount={applied_credits / 100} negative />
              </TableRow>
            }

            {!!final_credits &&
              <TableRow>
                <TableCell>
                  <Typography variant="button" display="block" color="textPrimary">Total credits</Typography>
                  <Typography variant="caption" color="textSecondary">Total account credits after transaction</Typography>
                </TableCell>
                <AmountCell currency={preferred_currency_code} amount={final_credits / 100} />
              </TableRow>
            }

            <TableRow>
              {auto_collection
                ? <TableCell size="medium"><Typography sx={{ fontWeight: 'bold' }} variant="button" color="textPrimary">Amount Due Now</Typography></TableCell>
                : <TableCell size="medium">
                  <Typography sx={{ fontWeight: 'bold' }} variant="button" display="block" color="textPrimary">Unbilled charges</Typography>
                  <Typography variant="caption" color="textSecondary">Total unbilled charges after transaction</Typography>
                </TableCell>
              }
              <AmountCell currency={preferred_currency_code} amount={amount_due / 100} />
            </TableRow>

          </TableBody>
        </Table>

      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()} color="primary">Cancel</Button>
        <Button onClick={() => onClose(true)} variant="contained" color="secondary">Confirm</Button>
      </DialogActions>
    </>
  )
}, { ['aria-labelledby']: 'form-dialog-title' })

const EstimateRow = ({ plans, currency, subscription }) => {
  const { unbilled_charge_estimates = {}, credit_note_estimates = {} } = subscription.estimates;
  const { description = '', unit_amount, quantity, amount, voided_amount, discount_amount, date_from, date_to } = unbilled_charge_estimates;

  if (!!quantity) {
    const value = (amount - discount_amount) - voided_amount;
    const unit_total = accounting.formatMoney(unit_amount / 100, { symbol: currency, format: '%s %v' });

    const from = format(fromUnixTime(date_from), 'PP');
    const to = format(fromUnixTime(date_to), 'PP');

    const details = `(${unit_total} x ${quantity}) ${from} to ${to}`;

    return (
      <TableRow>
        <TableCell>
          <Typography variant="subtitle2">{description}</Typography>
          <Typography variant="caption" color="textSecondary">{details}</Typography>
        </TableCell>
        <AmountCell currency={currency} amount={Math.abs(value / 100)} negative={value < 0} />
      </TableRow>
    )
  }

  if (!credit_note_estimates.total) {
    const { name } = plans.find(({ id }) => id === subscription.data.plan_id) ?? {}
    return (
      <TableRow>
        <TableCell>
          <Typography variant="subtitle2">{name}</Typography>
          <Typography variant="caption" color="textSecondary">Modifications will be applied without charges</Typography>
        </TableCell>
        <AmountCell currency={currency} amount={0} />
      </TableRow>
    )
  }

  const from1 = format(fromUnixTime(credit_note_estimates.date_from), 'PP');
  const to1 = format(fromUnixTime(credit_note_estimates.date_to), 'PP');

  const details1 = `${from1} to ${to1}`;

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">{credit_note_estimates.description}</Typography>
        <Typography variant="caption" color="textSecondary">{details1}</Typography>
      </TableCell>
      <AmountCell currency={credit_note_estimates.currency_code} amount={credit_note_estimates.total / 100} negative />
    </TableRow>
  )
}