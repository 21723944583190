import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { translateCode, validatorResolver } from 'src/services/validation';
import { observer } from 'mobx-react';
import { Unstable_Grid2 as Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { ReactLink } from 'src/components/base/ReactLink';

import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';
import { CODE_NEED_ACTIVATION, CODE_TFA_REQUIRED } from 'shared/status-error/codes';

import logoImage from 'assets/images/logo-main.svg';

const MainLogo = styled('div')({
  width: '185px',
  height: '110px',
  marginBottom: '30px',
  background: `url(${logoImage}) no-repeat`,
})

export const SignIn = observer(({ subsciption_id }) => {
  const { userStore } = useStore();
  const [twofactor, setTwofactor] = useState(false);
  const inputLabelProps = !!subsciption_id ? { shrink: true } : undefined;

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: validatorResolver({
      email: ['required', 'email'],
      password: 'required',
      code: { predicate: value => (twofactor && !value) ? 'REQUIRED' : '' },
    })
  });

  useEffect(() => {
    if (subsciption_id) {
      runAction(async () => {
        setValue('email', await userStore.getEmailBySubscription({ id: subsciption_id }));
      })
    }
    else { setValue('email', '') }
  }, [subsciption_id]);

  async function handleLogIn(data) {
    await runAction(async () => {
      try {
        await userStore.login(data);
      }
      catch (error) {
        if (error.status === 401) { return userStore.displayErrorSnack(error.message) }
        if (error.code === CODE_NEED_ACTIVATION) {
          return userStore.displayErrorSnack('This user is pending activation. If you did not receive the activation email, please click on the ‘Forgot Password?’ link to resend it.');
        }

        if (error.code === CODE_TFA_REQUIRED) { return setTwofactor(true) }

        throw error;
      }
    });
  }

  return (
    <Grid xs={11} md={6} container direction="column">
      <form onSubmit={handleSubmit(handleLogIn)} noValidate>
        <MainLogo />

        <TextField name="email" label="Email" autoComplete="username" autoFocus type="email" fullWidth variant="filled" margin="normal"
          InputLabelProps={inputLabelProps}
          error={!!errors.email} helperText={translateCode(errors.email)} {...register('email')} disabled={!!subsciption_id || twofactor} />

        <TextField name="password" label="Password" autoComplete="current-password" type="password" fullWidth variant="filled" margin="normal"
          error={!!errors.password} helperText={translateCode(errors.password)} {...register('password')} disabled={twofactor} />

        {twofactor &&
          <TextField name="code" label="Enter the code from your authentication app" placeholder="123456"
            fullWidth variant="filled" margin="normal" autoComplete="off"
            error={!!errors.code} helperText={translateCode(errors.code)} {...register('code')} />
        }

        <Grid sx={{ mt: 2, mb: 4 }}>
          <LoadingButton sx={{ width: 120 }} type="submit" loading={userStore.isLoading}
            variant="contained" color="secondary">Login</LoadingButton>
        </Grid>

        <Grid container justifyContent="space-between">
          <ReactLink color="secondary" to="/reset">Forgot Password?</ReactLink>
          {!!subsciption_id
            ? <ReactLink color="secondary" to="/">Log in as a different user</ReactLink>
            : <ReactLink color="secondary" to="/register">Not Registered Yet?</ReactLink>
          }
        </Grid>
      </form>
    </Grid>
  )
})
