import accounting from 'accounting';
import { fromUnixTime, format } from 'date-fns';
import { AmountCell } from 'src/components/base/TableCells';

import { DialogTitle, DialogActions, DialogContent, Typography, Button } from '@mui/material';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { StatusChip } from 'src/components/base/StatusChip';

import {
  INVOICE_STATUS_PAID, INVOICE_STATUS_POSTED, INVOICE_STATUS_PAYMENT_DUE,
  INVOICE_STATUS_VOIDED, INVOICE_STATUS_NOT_PAID, INVOICE_STATUS_PENDING,
} from 'shared/services/chargebee/constants';

export const InvoiceDialog = withModalDialog(({ customer, invoice, onClose }) => {
  const { id, status, items, total, amount_to_collect, due_date, currency } = invoice;
  const date = format(fromUnixTime(due_date), 'PP');

  return (
    <>
      <DialogTitle id="form-dialog-title">
        <Typography component="span" variant="inherit">Invoice # {id}</Typography><br />
        <InvoiceStatusChip status={status} />&nbsp;<Typography component="span" variant="caption">{`Due Date ${date}`}</Typography>
      </DialogTitle>

      <DialogContent>
        <Table size="small">
          <TableBody>

            {items.map((item, index) => <InvoiceItemRow key={index} item={item} currency={currency} />)}

            <TableRow>
              <TableCell size="medium"><Typography sx={{ fontWeight: 'bold' }} variant="button" color="textPrimary">Total</Typography></TableCell>
              <AmountCell currency={currency} amount={Math.abs(total / 100)} negative={total < 0} />
            </TableRow>


            <TableRow>
              <TableCell size="medium"><Typography sx={{ fontWeight: 'bold' }} variant="button" color="textPrimary">Amount Due Now</Typography></TableCell>
              <AmountCell currency={currency} amount={amount_to_collect / 100} />
            </TableRow>

          </TableBody>
        </Table>

      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose()} color="primary">Cancel</Button>
        <Button onClick={() => onClose(true)} variant="contained" color="secondary">Confirm</Button>
      </DialogActions>
    </>
  )
}, { ['aria-labelledby']: 'form-dialog-title' })

const InvoiceItemRow = ({ item, currency }) => {
  const { quantity, description, unit_amount, amount } = item;

  const unit_total = accounting.formatMoney(unit_amount / 100, { symbol: currency, format: '%s %v' });
  const details = `(${quantity} x ${unit_total})`;

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">{description}</Typography>
        <Typography variant="caption" color="textSecondary">{details}</Typography>
      </TableCell>
      <AmountCell currency={currency} amount={Math.abs(amount / 100)} negative={amount < 0} />
    </TableRow>
  )
}

const statuses = {
  [INVOICE_STATUS_PAID]: { label: 'Paid', color: '#E4FEE9' },
  [INVOICE_STATUS_POSTED]: { label: 'Posted', color: '#FEFEFE' },
  [INVOICE_STATUS_PAYMENT_DUE]: { label: 'Payment Due', color: '#EE6464' },
  [INVOICE_STATUS_VOIDED]: { label: 'Voided', color: '#FEFEFE' },
  [INVOICE_STATUS_NOT_PAID]: { label: 'Not Paid', color: '#cc3434' },
  [INVOICE_STATUS_PENDING]: { label: 'Pending', color: '#FEFEFE' },
}

export const InvoiceStatusChip = ({ status }) => {
  const params = statuses[status];
  return <StatusChip {...params} />
}